<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { LockClosedIcon } from "@heroicons/vue/24/outline";
import Avatar from "../Avatar.vue";

const civiqaUser = useCiviqaUser();
</script>

<template>
    <Menu as="div" class="relative inline-block text-left">
        <div>
            <MenuButton
                class="relative flex rounded-full bg-green-50 text-sm outline-none ring-2 ring-green-600 hover:cursor-pointer hover:bg-green-100 hover:ring-green-700"
            >
                <Avatar
                    class="size-10"
                    :photo-url="civiqaUser?.photoUrl"
                    :name="civiqaUser?.fullName"
                    :no-tooltip="true"
                />
                <LockClosedIcon
                    v-if="civiqaUser?.isStaff"
                    class="4 mx-2 h-4 self-center text-green-600"
                    aria-hidden="true"
                />
            </MenuButton>
        </div>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
                <div class="py-1">
                    <MenuItem v-slot="{ active }">
                        <NuxtLink
                            href="/app/boards"
                            class="block px-4 py-2 text-sm"
                            :class="[
                                active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                            ]"
                        >
                            Bill Boards
                        </NuxtLink>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                        <NuxtLink
                            href="/logout"
                            class="block px-4 py-2 text-sm"
                            :class="[
                                active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                            ]"
                        >
                            Log out
                        </NuxtLink>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>
